$text-dark: #232525;
$text-grey: #96a2a7; // chagne it to #666666?? devils ash
$border-grey: #b6bcc2;
$dark-grey: #343434;
$smoke-grey: #434343;
$background-grey: #f6f7f7;
$light-grey: #d8d8d8;
$brand-red: #f53331;
$blue: #37517c;
$link-green: #009688;
$ui-green: #0aab9c;
$light-green: #eafcfb;
$white: #ffffff;
$black: #000 !default;

.text-dark {
  color: $text-dark !important;
}

.text-grey {
  color: $text-grey !important;
}

.text-light-grey {
  color: $light-grey !important;
}

.text-dark-grey {
  color: $dark-grey !important;
}

.text-white {
  color: $white !important;
}

.text-green {
  color: $link-green !important;
}

.text-light-green {
  color: $light-green !important;
}

.text-red {
  color: $brand-red !important;
}

.text-blue {
  color: $blue !important;
}

.bg-grey {
  background-color: $background-grey;
}

.bg-more-grey {
  background-color: #e4e7e7
}

.bg-dark-grey {
  background-color: $text-dark;
}

.bg-smoke-grey {
  background-color: $smoke-grey;
}

.bg-light-green {
  background-color: $light-green !important;
}

.bg-black {
  background-color: #000000;
}

.bg-red {
  background-color: $brand-red;
}
