::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $text-grey !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $text-grey !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: $text-grey !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $text-grey !important;
}

label {
  font-weight: bold;
}

input:disabled {
  background: #fff !important;
  color: $text-grey;
  font-weight: 300;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select:required:invalid {
  color: $text-grey !important;
}

.form .form-control {
  &:not(textarea) {
    height: 48px;
  }

  border: 1px solid $border-grey;
}

.form-control-grey {
  background-color: #f6f7f7;
  border: none;
}

.form-group.form-group-border-0 .form-control {
  border: 0 !important;
}

.form.show-errors .form-control.ng-invalid.ng-touched.ng-dirty,
.form.show-success .form-control.ng-valid.ng-touched,
.show-errors.invalid,
.form-group.invalid button.btn {
  border-bottom: 2px solid $brand-red;
}

.form.show-errors .form-control.ng-invalid.ng-touched.ng-dirty.transparent {
  border-bottom: 1px solid $brand-red;
}

*:focus {
  outline: none;
}

input::placeholder {
  color: $text-grey !important;
  font-weight: 300 !important;
}

.form-control:focus {
  box-shadow: none !important;
  border: 1px solid $text-grey;
}

.form-group.transparent {
  border-bottom: 1px solid $text-grey;
  padding-left: 0;
  margin-right: 1em;
  padding-right: 0;

  label {
    font-size: 14px;
    margin-bottom: -1px;
  }

  label span {
    font-size: 16px;
  }

  input.form-control {
    background: transparent;
    border: none;
    border-radius: 0;
    padding-bottom: 0;
    padding-top: 0;
    height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  input:focus {
    box-shadow: none;
  }

  .input-icon-container {
    position: relative;

    i {
      position: absolute;
      top: 4px;
    }

    input.form-control {
      padding-left: 1.5rem;
    }
  }
}

.form-group.transparent.show-errors {
  border-bottom: 1px solid $brand-red;
  margin-bottom: 0;
}

.form-group.bb {
  border-bottom: 1px solid $light-grey;
  padding-bottom: 19px;
}

.dropdown-container.fw {
  min-width: 233px;
}

.admin-form {
  label {
    font-size: 0.9375rem;
    font-weight: 400;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .form-control {
    border: 1px solid #b6bcc2;
  }

  .form-control:not(textarea) {
    height: 48px;
  }
}

.form-control.transparent {
  background: transparent;
  border: none;
  border-bottom: 1px solid #b6bcc2;
  margin-top: 0.5rem;
  border-radius: 0;
  padding-left: 0;
  padding-bottom: 0;
  height: 40px !important;
}

.form-control.search {
  border-radius: 20px !important;
  padding: 0.375rem 1rem;
  line-height: 3;
}

.input-group i {
  right: 15px;
  top: 11px;
  color: #96a2a7;
  position: absolute;
  z-index: 3;
}

/* input focus effects css */
:focus {
  outline: none;
}

.input-focus-border ~ .focus-border {
  position: absolute;
  bottom: 0;
  right: 100%;
  width: 0;
  height: 4px;
  background-color: $brand-red;
  transition: 0.3s;
  border-radius: 5px;
}

// you can use css on focus or you can do the following
// [class.active]="boolean condition"
.input-focus-border:focus ~ .focus-border,
.input-focus-border.active ~ .focus-border {
  width: 100%;
  transition: 0.3s;
  right: 0;
}

.drop-down,
.dropdown-menu {
  .dropdown-item {
    font-weight: 300;
    text-overflow: clip;
    overflow-x: hidden;
  }
}

.icon-close-circle-container {
  border-radius: 50px;
  padding: 20px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #f6f7f7;
  }
}

option {
  font-weight: lighter;
  &:checked {
    background: $text-dark !important;
    color: #fff;
  }
}
