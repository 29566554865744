@import "environment";
@import "colors";
/* purgecss start ignore */
.mapboxgl-marker,
.mapboxgl-popup {
  will-change: transform;
  top: 10px;
  left: 0;
}

.mapboxgl-map {
  overflow: hidden;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mapboxgl-canvas-container.mapboxgl-interactive,
.mapboxgl-ctrl-nav-compass {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.mapboxgl-canvas-container.mapboxgl-interactive:active,
.mapboxgl-ctrl-nav-compass:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right {
  position: absolute;
  pointer-events: none;
  z-index: 2;
}

.mapboxgl-ctrl {
  clear: both;
  pointer-events: auto;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl {
  margin: 10px 0 0 10px;
  float: left;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 10px 10px 0 0;
  float: right;
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
  margin: 0 0 10px 10px;
  float: left;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  margin: 0 10px 10px 0;
  float: right;
}

.mapboxgl-ctrl-group {
  border-radius: 4px;
  -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background: #fff;
}

.mapboxgl-ctrl-group > button {
  width: 30px;
  height: 30px;
  display: block;
  padding: 0;
  outline: 0;
  border: none;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.mapboxgl-ctrl > button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.mapboxgl-ctrl > button:last-child {
  border-bottom: 0;
}

.mapboxgl-ctrl > button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.mapboxgl-ctrl-icon,
.mapboxgl-ctrl-icon > .mapboxgl-ctrl-compass-arrow {
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mapboxgl-ctrl-icon {
  padding: 5px;
}

.mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-waiting {
  -webkit-animation: mapboxgl-spin 2s infinite linear;
  -moz-animation: mapboxgl-spin 2s infinite linear;
  -o-animation: mapboxgl-spin 2s infinite linear;
  -ms-animation: mapboxgl-spin 2s infinite linear;
  animation: mapboxgl-spin 2s infinite linear;
}

@-webkit-keyframes mapboxgl-spin {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes mapboxgl-spin {
  0% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes mapboxgl-spin {
  0% {
    -o-transform: rotate(0);
  }

  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes mapboxgl-spin {
  0% {
    -ms-transform: rotate(0);
  }

  100% {
    -ms-transform: rotate(360deg);
  }
}

@keyframes mapboxgl-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  padding: 0 5px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  padding-top: 2px;
  padding-bottom: 2px;
  margin: 0 10px 10px;
  position: relative;
  padding-right: 24px;
  background-color: #fff;
  border-radius: 3px 12px 12px 3px;
  visibility: hidden;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact:hover {
  visibility: visible;
}

.mapboxgl-ctrl-attrib a {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}

.mapboxgl-ctrl-attrib a:hover {
  color: inherit;
  text-decoration: underline;
}

.mapboxgl-ctrl-attrib .mapbox-improve-map {
  font-weight: 700;
  margin-left: 2px;
}

.mapboxgl-ctrl-scale {
  background-color: rgba(255, 255, 255, 0.75);
  font-size: 10px;
  border-width: medium 2px 2px;
  border-style: none solid solid;
  border-color: #333;
  padding: 0 5px;
  color: #333;
}

.mapboxgl-popup {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  pointer-events: none;
}

.mapboxgl-popup-anchor-top,
.mapboxgl-popup-anchor-top-left,
.mapboxgl-popup-anchor-top-right {
  -webkit-flex-direction: column;
  flex-direction: column;
}

.mapboxgl-popup-anchor-bottom,
.mapboxgl-popup-anchor-bottom-left,
.mapboxgl-popup-anchor-bottom-right {
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.mapboxgl-popup-anchor-left {
  -webkit-flex-direction: row;
  flex-direction: row;
}

.mapboxgl-popup-anchor-right {
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.mapboxgl-popup-tip {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  z-index: 1;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  -webkit-align-self: center;
  align-self: center;
  border-top: none;
  border-bottom-color: #fff;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  -webkit-align-self: flex-start;
  align-self: flex-start;
  border-top: none;
  border-left: none;
  border-bottom-color: #fff;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  -webkit-align-self: flex-end;
  align-self: flex-end;
  border-top: none;
  border-right: none;
  border-bottom-color: #fff;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  -webkit-align-self: center;
  align-self: center;
  border-bottom: none;
  border-top-color: #fff;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  -webkit-align-self: flex-start;
  align-self: flex-start;
  border-bottom: none;
  border-left: none;
  border-top-color: #fff;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  -webkit-align-self: flex-end;
  align-self: flex-end;
  border-bottom: none;
  border-right: none;
  border-top-color: #fff;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  -webkit-align-self: center;
  align-self: center;
  border-left: none;
  border-right-color: #fff;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  -webkit-align-self: center;
  align-self: center;
  border-right: none;
  border-left-color: #fff;
}

.mapboxgl-popup-close-button {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  border-radius: 0 3px 0 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
}

.mapboxgl-popup-close-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.mapboxgl-popup-content {
  position: relative;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 10px 10px 15px;
  pointer-events: auto;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-content {
  border-top-left-radius: 0;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-content {
  border-top-right-radius: 0;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content {
  border-bottom-left-radius: 0;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content {
  border-bottom-right-radius: 0;
}

.mapboxgl-marker {
  position: absolute;
}

.mapboxgl-user-location-dot-stale {
  background-color: #aaa;
}

.mapboxgl-user-location-dot-stale:after {
  display: none;
}

.mapboxgl-crosshair,
.mapboxgl-crosshair .mapboxgl-interactive,
.mapboxgl-crosshair .mapboxgl-interactive:active {
  cursor: crosshair;
}

.mapboxgl-boxzoom {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background: #fff;
  border: 2px dotted #202020;
  opacity: 0.5;
}

.atlas-map {
  overflow: unset;
}

.atlas-map-canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  -ms-user-select: none;
}

.atlas-map-canvas {
  pointer-events: auto;
}

.map-copyright {
  color: #999;
  position: absolute;
  bottom: 1px;
  right: 5px;
  font-size: 10px;
  height: 10px;
  width: 100%;
  line-height: 10px;
  text-align: right;
}

.marker-collection-container,
.popup-collection-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  overflow: hidden;
  top: 0;
}

.marker-collection-container {
  pointer-events: none;
}

.marker-container {
  pointer-events: auto;
}

.popup-collection-container {
  pointer-events: none;
}

.popup-content-container {
  display: block;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  width: 350px;
  border-radius: 5px;
  padding: 10px 10px 1px 10px;
}

.popup-content-container.scrollable {
  max-height: 362px;
  overflow-y: scroll;
}

@media (max-width: 767.98px) {
  .popup-content-container.scrollable {
    max-height: 300px;
  }
}

.popup-arrow {
  display: block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  position: relative;
  left: 50%;
  margin-left: -10px;
}

.popup-close {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  color: #444;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 12px;
  height: 12px;
  width: 14px;
  text-align: center;
  cursor: pointer;
  background: 0 0;
  border: 0;
  padding: 0;
}

.popup-close:hover {
  color: #222;
}

.popup-container {
  display: block;
  pointer-events: auto;
}

.atlas-control-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.top-left.subcontrol-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.top-right.subcontrol-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.bottom-left.subcontrol-container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.bottom-right.subcontrol-container {
  position: absolute;
  bottom: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.non-fixed.subcontrol-container {
  width: 100%;
  height: 100%;
}

.hidden-accessible-element {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  display: none;
}

.azure-maps-control-container {
  margin: 10px;
  margin-right: 10px;
  border-collapse: collapse;
  display: flex;
  flex-wrap: nowrap;
  pointer-events: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}

.azure-maps-control-container:not(:first-child) {
  margin-top: 2px;
}

.azure-maps-control-container:not(:last-child) {
  margin-bottom: 2px;
}

.azure-maps-control-button {
  margin: 0;
  padding: 0;
  border: none;
  border-collapse: collapse;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  line-height: 32px;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 100;
  background-color: white;
}

.azure-maps-control-button:focus {
  outline: 0;
}

.azure-maps-control-button.light {
  background-color: #fff;
}

.azure-maps-control-button.dark {
  background-color: #011c2c;
}

.azure-maps-control-button.light:active {
  background-color: #f1f1f1;
}

.azure-maps-control-button.dark:active {
  background-color: #43474c;
}

.azure-maps-control-button.zoom-in {
  background-size: 15px 15px;
  background-image: url($baseImagePath + "/pubweb/marketplace/images/icons/zoom-in.svg");
  position: relative;
}

.azure-maps-control-button.zoom-in.light:after {
  border-bottom: 0.5px solid #bababa;
}

.azure-maps-control-button.zoom-in.dark:after {
  border-bottom: 0.5px solid #43474c;
}

.azure-maps-control-button.zoom-in:active {
  background-image: url($baseImagePath + "/pubweb/marketplace/images/icons/zoom-in.svg");
}

.azure-maps-control-button.zoom-out {
  background-size: 15px 15px;
  background-image: url($baseImagePath + "/pubweb/marketplace/images/icons/zoom-out.svg");
}

.azure-maps-control-button.zoom-out:active {
  background-image: url($baseImagePath + "/pubweb/marketplace/images/icons/zoom-out.svg");
}

.azure-maps-control-button.curr-style {
  position: relative;
}

.azure-maps-control-container > .style-options {
  display: flex;
  flex-direction: row;
}

.azure-maps-control-container > .tooltiptext {
  display: none;
}

.azure-maps-control-container .azure-maps-control-button.curr-style img,
.azure-maps-control-container > .style-options img {
  width: 28px;
  height: 28px;
  margin: 2px;
  padding: 0;
  border-collapse: collapse;
}

.azure-maps-control-container > .style-options .azure-maps-control-button:active img {
  opacity: 0.6;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: $brand-red;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: $white;
  position: absolute;
  border-radius: 50%;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px $brand-red;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

.custom-marker {
  width: 28px;
  height: 28px;
  border-radius: 50% 50% 50% 50%;
  background: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -18px 0 0 -18px;
}

.custom-marker:after {
  content: "";
  width: 20px;
  height: 20px;
  margin: 4px 0 0 4px;
  background: #f53331;
  position: absolute;
  border-radius: 50%;
}

.custom-marker-background {
  background-color: rgba($color: red, $alpha: 0.1);
  border-radius: 50%;
  height: 75px;
  width: 75px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -42px 0px 0px -42px;
  z-index: -2;
}

.hidden-accessible-element {
  position: relative;
}
.sr-only {
  display: none;
}
/* purgecss end ignore */
