//https://swimlane.gitbook.io/ngx-datatable/themeing
/* purgecss start ignore */
.ngx-datatable {
  box-shadow: none;
  font-size: 15px;
  font-weight: 300;

  .datatable-header {
    border-bottom: 1px solid $light-grey;

    .datatable-header-cell {
      text-align: left;
      padding: 0.9rem 1.2rem 0.9rem 1.2rem;

      .datatable-header-cell-label {
        font-weight: 300;
        line-height: 24px;
        font-size: 11px;
        text-transform: uppercase;
        color: $text-grey;
        letter-spacing: 1px;
      }
    }
  }

  &.clickable .datatable-body .datatable-body-row {
    cursor: pointer;
  }

  .datatable-body {
    .datatable-body-row {
      border-top: 1px solid $light-grey;
      width: initial !important; // might not be what we need

      .datatable-body-cell {
        padding: 0.9rem 1.2rem 0.9rem 0;
      }

      // &:hover {
      //   background: #f6f7f7;
      //   transition-property: background;
      //   transition-duration: 0.3s;
      //   transition-timing-function: linear;
      // }

      &:focus {
        background-color: #232837;
      }

      &.active {
        background-color: #1483ff;
        color: #fff;
      }
    }
  }

  .datatable-body-cell-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .datatable-footer {
    margin-top: -1px;

    .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 1.2rem;
      font-size: 14px;
      color: $text-grey;
    }

    .datatable-pager {
      margin: 0 10px;
      vertical-align: top;

      ul {
        li {
          margin: 10px 0px;

          a {
            height: 33px;
            width: 33px;
            border-radius: 50%;
            padding-top: 6px;
            color: $link-green;
            font-size: 14px;
          }

          &:not(.disabled) {
            &.active a,
            &:hover a {
              background-color: $link-green;
              font-weight: bold;
              color: white;
            }
          }
        }
      }

      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0;
        border-radius: 3px;
        margin: 0 3px;
        text-align: center;
        vertical-align: top;
        text-decoration: none;
        vertical-align: bottom;
        color: #72809b;
      }

      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }

  .datatable-group-header {
    background-color: #f6f7f7;
    font-size: 12px;
    font-weight: 300;
    display: flex !important;
    align-items: center !important;
    height: 30px;
    padding-left: 1.2rem;
    div {
      letter-spacing: 1px;
    }
  }

  .datatable-summary-row {
    .datatable-body-row {
      .datatable-body-cell {
        font-weight: bold;
      }
    }
  }

  .empty-row {
    padding: 0.9rem 1.3rem;
  }
}

.sort-btn {
  font-family: "icomoon" !important;
  font-size: 6px;
  margin-left: 4px;
  line-height: 93% !important;
}

.datatable-icon-up {
  transform: rotate(180deg);

  &::before {
    content: "\e903";
  }
}

.datatable-icon-down::before {
  content: "\e903";
}

.datatable-footer-inner {
  padding-top: 20px;
  flex-direction: column !important;
}

.datatable-pager {
  flex: none !important;
  line-height: initial;
}

// hide first page and last page animation
.pager li:last-child {
  display: none !important;
}

.pager li:first-child {
  display: none !important;
}

.pager {
  li:nth-child(2):hover a i,
  li:nth-last-child(2):hover a i {
    color: #ffffff;
  }

  li:nth-child(2) a,
  li:nth-last-child(2) a {
    border: 2px solid $link-green;
    border-radius: 50%;
    padding-top: 5px !important;
  }
}

.pager .disabled:nth-child(2),
.pager .disabled:nth-last-child(2) {
  a {
    border: 2px solid rgba(0, 150, 136, 0.3);
  }
}

.pager .disabled:nth-child(2) a i,
.pager .disabled:nth-child(2):hover a i,
.pager .disabled:nth-last-child(2) a i,
.pager .disabled:nth-last-child(2):hover a i {
  color: rgba(0, 150, 136, 0.3);
}

.datatable-icon-right,
.datatable-icon-left {
  font-family: "icomoon" !important;
  font-style: normal;
  color: $link-green;
  font-size: 11px !important;
  font-weight: bold;
  line-height: 21px !important;
}

.datatable-icon-right {
  padding-left: 2px;
}

.datatable-icon-left {
  padding-right: 2px;
}

.datatable-icon-right::before {
  content: "\e900";
}

.datatable-icon-left::before {
  content: "\e901";
}

.ngx-datatable:not([ng-reflect-group-rows-by]) .datatable-row-wrapper:first-child .datatable-body-row {
  border-top: none;
}

.ngx-datatable[ng-reflect-group-rows-by] .datatable-row-wrapper .datatable-body-row:first-of-type {
  border-top: none;
}

.ngx-datatable:not(.cell-selection) .datatable-body-row .datatable-row-group .show-on-hover {
  opacity: 0;
  transition: all 0.25s linear;
}

.ngx-datatable:not(.cell-selection) .datatable-body-row:hover .datatable-row-group .show-on-hover {
  opacity: 1;
  transition: all 0.25s linear;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table.transparent tr {
  background-color: transparent;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.btn-group-action {
  .more-actions {
    border-left: none;
    display: flex !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// mobile table cards
@media screen and (max-width: 992px) {
  .table-card {
    border: none;
    background-color: transparent;
  }
  // table thead {
  .datatable-header {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .datatable-row-wrapper {
    display: block !important;
  }

  // table tr {
  .ngx-datatable .datatable-row-center.datatable-row-group {
    // border-bottom: 3px solid #ddd;
    display: block !important;
    margin-bottom: 1.5em;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
  }

  .datatable-body-cell {
    width: 100% !important;
    padding: 0.5rem 1.2rem 0.5rem 1.2rem !important;
    display: block !important;
    &:last-child {
      padding-bottom: 1rem !important;
    }
    &:first-child {
      padding-top: 1rem !important;
    }
  }

  .datatable-body-cell {
  }

  .ngx-datatable .datatable-body-row {
    display: inline !important;
  }

  // table td {
  .datatable-body-cell-label {
    display: block !important;
    text-align: right;
  }

  // table td::before {
  .datatable-body-cell-label::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  table td:last-child {
    border-bottom: 0;
  }

  .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
    height: initial !important;
  }
}
/* deskstop and up */
@media (min-width: 992px) {
  .datatable-body {
    .datatable-body-row {
      margin-left: 1.3rem;
      margin-right: 1.3rem;
    }
  }

  .datatable-body-cell {
    display: flex !important;
    align-items: center !important;
  }

  .img-table {
    height: 105px;
    width: 105px;
  }
}

.ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell {
  line-height: 1 !important;
}
/* purgecss end ignore */

.danger-row {
  .datatable-body-cell:first-child {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}

.warning-row {
  .datatable-body-cell:first-child {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
}

.success-row {
  .datatable-body-cell:first-child {
    color: #004e47;
    background-color: #cceae7;
    border-color: #b8e2de;
  }
}
