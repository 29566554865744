.topbar-search-datepicker ngb-datepicker {
  transform: translate(-89px, 38px) !important;
}

// for mobile move the datepicker to the left so it is centered
@include media-breakpoint-down(sm) {
  .topbar-search-datepicker ngb-datepicker {
    transform: translate(-78px, 36px) !important;

    .dropdown-arrow {
      left: 84px;
    }

    .dropdown-arrow-job-done {
      left: 149px;
    }
  }
}

.add-to-cart-datepicker ngb-datepicker {
  transform: translate(-125px, 82px) !important;

  .dropdown-arrow {
    left: 154px;
  }

  .dropdown-arrow-job-done {
    left: 200px;
  }
}

@include media-breakpoint-down(md) {
  .add-to-cart-datepicker ngb-datepicker {
    transform: translate(0, 82px) !important;

    .dropdown-arrow {
      left: 10px;
    }

    .dropdown-arrow-job-done {
      left: 78px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .checkout-review-datepicker ngb-datepicker {
    transform: translate(15px, 222px) !important;

    .dropdown-arrow {
      left: 10px;
    }

    .dropdown-arrow-job-done {
      left: 62px;
    }
  }
}

.machine-gallery-container {
  .swiper-pagination-bullet {
    opacity: 0.5;
    background-color: #ffffff;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }
}

.azure-map-logo {
  display: none;
}

.inactive-bullet {
  border: 1px solid #fff !important;
  opacity: 0.8 !important;
  padding: 0.25rem !important;
}
.active-bullet {
  background-color: #fff !important;
  opacity: 1 !important;
}
.bottom-0bullets {
  bottom: 0px !important;
}

.clean-active-bullet {
  background-color: $dark-grey !important;
}

.swiper-button-next:not(.card-carousel),
.swiper-button-prev:not(.card-carousel) {
  color: black !important;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 50%;
  padding-top: 45px;
  padding-bottom: 45px;
  padding-left: 45px;
  padding-right: 45px;
  transform: scale(0.5);
  margin-top: -40px !important;
}

.swiper-button-prev:not(.card-carousel) {
  margin-left: -20px;
}

.swiper-button-next:not(.card-carousel) {
  margin-right: -20px;
}

/* purgecss start ignore */
.mat-paginator-range-actions {
  .mat-paginator-range-label {
    display: none;
  }
  .mat-button-base {
    margin-left: 5px;
    margin-right: 5px;
  }
}
/* purgecss end ignore */
