// You can add global styles to this file, and also import other style files
@import "./bootstrap-overrides.scss";
@import "~swiper/swiper.min.css";
@import "~ngx-toastr/toastr";
@import "./typography.scss";
@import "./helpers.scss";
@import "./forms.scss";
@import "./buttons.scss";
@import "./library-overrides.scss";
@import "./modals.scss";
@import "./toastr.scss";
@import "./tables.scss";

body {
  color: $text-dark;
  overflow-x: hidden;
}

.market-body {
  margin-top: 86px;
}

@include media-breakpoint-down(md) {
  .market-body {
    margin-top: 66px;
  }
}

img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
}

.no-scroll {
  overflow: hidden;
}

.dropdown-menu {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.06);
}

.icon-close {
  cursor: pointer;
}

.icon-close.default {
  cursor: default;
}

.icon-close.disabled {
  color: $light-grey !important;
  cursor: default;
}

.row.last {
  background-color: #f6f7f7;
}

.detail-container {
  background-color: #ffffff;
  padding: 0.85rem 1.5rem;
}

.badge-pill {
  padding: 8px 10px;
  font-size: 14px;
  font-weight: normal;

  .icon-close {
    color: #ffffff;
    font-size: 9px;
    margin-left: 2px;
    padding: 5px 2px 5px 5px;
    cursor: pointer;
  }

  &.badge-warning {
    color: #654708;
  }
}

.badge-outline-primary {
  background: white;
  border: 1px solid $brand-red;
  color: $brand-red;
}

.badge-outline-secondary {
  background: white;
  border: 1px solid $text-dark;
}

.badge-outline-secondary-overlay {
  background: transparent;
  border: 1px solid #fff;
}

.badge-outline-success {
  background: transparent;
  border: 1px solid $link-green;
  color: $link-green;
}

.admin-body,
.transportation-body {
  background-color: #f6f7f7;
}

.custom-rubbl-tooltip {
  &.white {
    .tooltip-inner {
      background-color: #ffffff;
      padding-bottom: 0;
    }

    .arrow::before {
      border-color: transparent transparent #ffffff transparent !important;
    }

    &.down {
      .arrow::before {
        border-width: 0 0.8rem 0.8rem 0.8rem !important;
        filter: drop-shadow(0px -2px 1px rgba(0, 0, 0, 0.25));
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .custom-tooltip .tooltip-inner {
    min-width: 250px;
  }
}

.list-items {
  padding-left: 17px;
  font-size: 15px;
  font-weight: 300;
}

// https://codepen.io/nxworld/pen/oLdoWb
.ribbon {
  width: 122px;
  height: 128px;
  overflow: hidden;
  position: absolute;

  &.ribbon-large {
    width: 137px;
    height: 135px;
  }
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 240px;
  padding: 15px 0;
  padding-left: 59px;
  background-color: $ui-green;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

/* top right*/
.ribbon-top-right {
  top: 0px;
  right: 0px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.container-default {
  margin-top: 86px;
  background-color: #f6f7f7;
  // keeping this here for now so when our footer actually has content we can use this :D
  // min-height: calc(100vh - 483px);
  min-height: calc(100vh - 265px);
  padding-bottom: 40px;
}

@include media-breakpoint-down(md) {
  .container-default {
    margin-top: 66px;
    // keeping this here for now so when our footer actually has content we can use this :D
    // min-height: calc(100vh - 463px);
    min-height: calc(100vh - 245px);
  }
}

// override progress bar
.ng-bar-placeholder {
  height: 3px !important;
}

.red-separator {
  border-bottom: 3px solid red;
  width: 50px;
}

.img-fit {
  object-fit: cover;
}

@media (min-width: 992px) {
  .container-sm {
    max-width: 940px;
  }
}

.icon-social {
  border: 1px solid black;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: $border-grey;
  }
}

.top-owner-icon-facets {
  font-size: 16px;
  color: #fdaf09;
  margin-top: 4px;
}
